import { useEffect, useRef, useState } from 'react';
import assets from '../assets';
import pareja from '../assets/img/Pareja_casa.webp';
import pareja_laptop from '../assets/img/Pareja_casa-laptop.webp';
import pareja_celular from '../assets/img/Pareja_casa_celular.jpg';
import constants from '../utils/constanst';
import { useScroll } from '../hooks/useScroll';
import { Link } from 'react-scroll';
import { useVisible } from '../hooks/useVisible';

const Header = () => {
    const [active, setActive] = useState(false);
    const animate = useRef(null) as any;
    const isVisible = useVisible(animate);

    const onClick = () => {
        if (window.document.body.clientWidth > 1024) {
            setActive(false)
        } else {
            setActive(!active);
        }
    };

    useEffect(() => {
        if (active) {
            document.body.style.overflowY = "hidden"
            window.scrollTo(0, 0)
        } else {
            document.body.style.overflowY = "auto"
        }
    }, [active])

    return (
        <>
            <header id="inicio">
                <div className="banner">
                    <picture>
                        <source
                            media="(min-width:1024px)"
                            srcSet={`${pareja_laptop}, 2x`}
                            type="image/webp"
                        />
                        <source
                            media="(min-width:720px)"
                            srcSet={`${pareja}, 2x`}
                            type="image/webp"
                        />
                        <img className='banner-img' src={pareja_celular} alt="Banner" loading="lazy" />
                    </picture>
                    <div className='bg-gradiant'>
                    </div>
                    <img
                        ref={animate}
                        className={`logo animate__animated ${isVisible ? "animate__zoomIn" : ""}`}
                        src={assets.iconos.logo_blanco}
                        alt="logo blanco"
                        loading="lazy"
                    />
                </div>
                <div className="mobile social-media">
                    {assets.iconos.menu?.map((item) => (
                        <div
                            key={item.name}
                            className="icon"
                            onClick={() => { open(item.link) }}
                        >
                            <img src={item.img} alt={item.name} loading="lazy" />
                        </div>
                    ))}
                </div>

                <div className="container-toggle" onClick={onClick}>
                    <div className={`nav-toggle  ${active ? "active" : ""} ${useScroll() > 180 ? 'visible' : ''}`} ><span></span></div>
                </div>

                <nav className={`menu ${active ? "active" : ""} ${useScroll() > 180 ? "visible" : ""}`}>
                    <div className="home link"
                    ><img
                            className="logo"
                            src={assets.iconos.logo_azul}
                            alt="logo azul"
                            loading="lazy"
                        /></div>
                    <ul>
                        {constants.links?.map(item => (
                            <li key={item.title}>
                                <Link to={item.link} spy={true} smooth={true} offset={window.document.body.clientWidth > 1024 ? -190 : 0} duration={200} className="link" onClick={onClick}>{item.title}</Link>
                            </li>
                        ))}
                    </ul>
                    <div className="social-media">
                        {assets.iconos.menu?.map((item) => (
                            <div
                                onClick={() => { open(item.link) }}
                                key={item.name}
                                className="icon link"
                            >
                                <img src={item.img} alt={item.name} loading="lazy" />
                            </div>
                        ))}
                    </div>
                </nav>
            </header >
        </>
    )
}

export default Header